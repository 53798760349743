import React, { } from 'react';
import Navbar1 from './Navbar/Navbar1';
import Body from './Body/Body';
import Footer from './Footer/Footer';
import socialData from './socialData';

const Privacy = () => {
    return (
        <div className="body_wrapper">
            <Navbar1 mClass="menu_two" mainlogo="logo-11.png" stickylogo="logo-00.png" />
            <div style={{ padding: 30, marginTop: 100, color: 'black', fontFamily: 'sans-serif' }}>
                <h2 style={{ fontFamily: 'sans-serif'}}>Privacy Policy</h2>

                <p>This Privacy Policy explains how Store2App collects, uses, discloses, and protects your information when you use our services. By using our services, you consent to the data practices described in this policy.</p>

                <h4 style={{ fontFamily: 'sans-serif' }}>Information We Collect</h4>
                <p>We collect various types of information in connection with the services we provide, including:</p>
                <ul>
                    <li><strong>Personal Information:</strong> Information that identifies you as an individual, such as your name, email address, phone number, and billing information.</li>
                    <li><strong>Usage Information:</strong> Information about how you use our services, including the pages you visit and the actions you take.</li>
                </ul>

                <h4 style={{ fontFamily: 'sans-serif' }}>How We Use Your Information</h4>
                <p>We use the information we collect to:</p>
                <ul>
                    <li>Provide, operate, and maintain our services.</li>
                    <li>Improve, personalize, and expand our services.</li>
                    <li>Understand and analyze how you use our services.</li>
                    <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the service, and for marketing and promotional purposes.</li>
                    <li>Process your transactions and manage your orders.</li>
                    <li>Find and prevent fraud.</li>
                </ul>

                <h4 style={{ fontFamily: 'sans-serif' }}>How We Share Your Information</h4>
                <p>We may share your information in the following situations:</p>
                <ul>
                    <li><strong>With Service Providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, and hosting services.</li>
                    <li><strong>For Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                    <li><strong>With Affiliates:</strong> We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy.</li>
                    <li><strong>For Legal Reasons:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
                </ul>

                <h4 style={{ fontFamily: 'sans-serif' }}>Data Security</h4>
                <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>

                <h4 style={{ fontFamily: 'sans-serif' }}>Your Data Protection Rights</h4>
                <p>Depending on your location, you may have the following rights regarding your personal information:</p>
                <ul>
                    <li>The right to access – You have the right to request copies of your personal information.</li>
                    <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
                    <li>The right to erasure – You have the right to request that we erase your personal information, under certain conditions.</li>
                    <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal information, under certain conditions.</li>
                    <li>The right to object to processing – You have the right to object to our processing of your personal information, under certain conditions.</li>
                    <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
                </ul>

                <h4 style={{ fontFamily: 'sans-serif' }}>Children's Privacy</h4>
                <p>Our services are not directed to children under 13, and we do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13 without verification of parental consent, we will take steps to remove that information from our servers.</p>

                <h4 style={{ fontFamily: 'sans-serif' }}>Changes to This Privacy Policy</h4>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

                <h4 style={{ fontFamily: 'sans-serif' }}>Contact Information</h4>
                <p>If you have any questions about these Terms of Use, please contact us at:</p>
                <p style={{ color: '#047857' }}>Email: ceo@saiftech.org
                    <br />Phone: +923074052841
                    <br />Address: Sialkot, Pakistan</p>
            </div>
            <Footer socialData={socialData} />
        </div>
    )
}

export default Privacy;