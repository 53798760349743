import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

class Footer extends Component {
    render() {
        let socialData = this.props.socialData;
        let noHeight = this.props.noHeight;
        return (
            <section className="footer-area-two" style={noHeight ? { backgroundColor: '#000' } : { backgroundColor: '#000' }}>
                <div className="container">
                    <div className="row footer-content" style={{ flexDirection: 'column' }}>

                        <button
                            onClick={() => {
                                window.location.href = '/'
                            }}
                            style={{ width: 150, border: '0px', padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img
                                style={{ alignSelf: 'center', width: 150 }}
                                src={require('../../image/logo-11.png')} />
                        </button>

                        <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', flexWrap:'wrap' }}>
                            <Fade cascade>
                                <a
                                    style={{ color: 'white', marginRight: 15 }}
                                    href='https://www.freelancer.com/u/saif0347'
                                    target='blank'>
                                    About Us
                                </a>
                                <a style={{ color: 'white', marginRight: 15 }} href='/terms'>Terms of Service</a>
                                <a style={{ color: 'white', marginRight: 15 }} href='/privacy'>Privacy Policy</a>
                                <a style={{ color: 'white' }} href='/affiliate'>Affiliate</a>
                            </Fade>
                        </div>

                        <div style={{ marginTop: 20, display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
                            <p style={{ alignSelf: 'center', fontSize: 12, fontFamily: 'sans-serif' }}>© 2024 Store2App by Freelancer.com's Top App Development Team</p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Footer;