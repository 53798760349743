import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Link } from 'react-scroll';
import showToast from '../toast';
import FooterTwo from '../Footer/Footer';
import Navbar1 from './../Navbar/Navbar1';
import socialData from '../socialData';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../redux/Actions'
import Api from '../Api/api'
import moment from 'moment';
import db from '../../firebase/firebase';
import { toast } from 'react-toastify';
import MyLoader from '../loader'

const darkTxt = '#000'
const themeTxt = '#113e49'
const lightTxt = '#6e6e6e'

const Signup = (props) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useDispatch()
    let shopifyData = useSelector(state => state.MyReducer.shopifyData);

    let profilePercentage = 25

    const [url, setUrl] = useState('');
    // const [appName, setAppName] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [pass, setPass] = useState('');
    const [cpass, setCPass] = useState('');
    const [terms, setTerms] = useState(false)
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const createAccount = async () => {
        let users = await db.collection('affiliate-users')
            .where('email', '==', email.toLowerCase())
            .get()
        if (users.docs.length > 0) {
            showToast('error', 'This email already exists!')
            return
        }

        setLoader(true)

        // generate unqiue code
        let code = parseInt((Math.random() * 10000) + '')
        while (true) {
            let users1 = await db.collection('affiliate-users')
                .where('code', '==', code+'')
                .get()
            if (users1.docs.length == 0) {
                break
            }
            // generate new
            code = parseInt((Math.random() * 10000) + '')
        }

        let user = {
            code: code+'',
            email: email,
            name: name,
            whatsapp: whatsapp,
            password: pass,
            clicks: [],
            balance: 0,
            payment: {
                accountType: '',
                bankName: '',
                accountNo: '',
                name: ''
            },
            added_date: new Date()
        }
        db.collection('affiliate-users').add(user);
        // send email
        sendEmailToCustomer()
        sendEmailToAdmin()
        // show success screen
        localStorage.setItem('user', JSON.stringify(user))
        navigate('/affiliate-home', { replace: false });
        setLoader(false)
        // try {
        //     import('react-facebook-pixel')
        //         .then((x) => x.default)
        //         .then((ReactPixel) => {
        //             console.log('pixel');
        //             ReactPixel.init('1270837980216929')
        //             ReactPixel.track('Purchase', { content_name: 'app', currency: "USD", value: 'signup' });
        //         })
        // }
        // catch (e) {
        //     //
        // }
    }

    const sendEmailToCustomer = () => {
        let mailParams = {
            "accessToken": '03X4H03hf8mNX3vmdhiLI',
            "service_id": 'service_vkaxqvg',
            "template_id": 'template_lv3syqu',
            "user_id": 'Hrb9PrGzCxo7wOfqg',
            "template_params": {
                "email": email,
                "message": 'Congratulations! Your affiliate account has been created. Please go to dashboard and find your affilate link. Thanks',
            }
        }
        fetch('https://api.emailjs.com/api/v1.0/email/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(mailParams)
        })
            .then(response => response.text())
            .then(result => {
                console.log('email sent: ' + result.text);
            })
            .catch(err => {
                console.log(err)
            });
    }

    const sendEmailToAdmin = () => {
        let mailParams = {
            "accessToken": '03X4H03hf8mNX3vmdhiLI',
            "service_id": 'service_vkaxqvg',
            "template_id": 'template_mwyz8jd',
            "user_id": 'Hrb9PrGzCxo7wOfqg',
            "template_params": {
                "from_name": "ShopifyToApp",
                "email": "",
                "message": 'You got a new Affiliate Signup!',
            }
        }
        fetch('https://api.emailjs.com/api/v1.0/email/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(mailParams)
        })
            .then(response => response.text())
            .then(result => {
                console.log('email sent: ' + result.text);
            })
            .catch(err => {
                console.log(err)
            });
    }

    return (

        <div className="body_wrapper" style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f4f4f9' }}>

            <Navbar1 mClass="menu_two" mainlogo="logo-11.png" stickylogo="logo-00.png" />

            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', marginTop: 120, marginBottom: 50, paddingBottom: 50, width: window.innerWidth <= 800 ? '90%' : '50%', alignSelf: 'center', paddingTop: 30, borderRadius: 10, alignItems: 'stretch' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ fontSize: 26, fontFamily: 'sans-serif', marginTop: 10, color: '#113E49' }}>
                        Create Affiliate Account
                    </div>
                </div>

                <div className='shopify-step-parent' style={{ width: '80%', display: 'flex', alignSelf: 'center', flexDirection: 'column', alignItems: 'stretch' }}>

                    <div style={{ marginTop: 3, marginLeft: 0, marginRight: 0, alignSelf: 'center', fontSize: 16, color: lightTxt, fontFamily: 'sans-serif', textAlign: 'center' }}>
                        Fill the details to create a partner account.
                        {/* Book a 30-minute consultation to walk through your app requirements and see a preview of your own app built by our team. */}
                    </div>

                    <div
                        className='col'
                        style={{ flex: 1, paddingTop: 0, marginTop: 20, display: 'flex', flexDirection: 'column', overflow: 'scroll' }}>

                        <TextField
                            id="outlined-basic"
                            label="Name"
                            style={{ marginTop: 10 }}
                            variant="outlined"
                            value={name}
                            size='medium'
                            onChange={(event) => {
                                setName(event.target.value)
                            }} />

                        <TextField
                            id="outlined-basic"
                            label="Email"
                            style={{ marginTop: 10 }}
                            variant="outlined"
                            value={email}
                            size='medium'
                            onChange={(event) => {
                                setEmail(event.target.value)
                            }} />

                        <TextField
                            id="outlined-basic"
                            label="Whatsapp (Optional)"
                            style={{ marginTop: 10 }}
                            type='phone'
                            variant="outlined"
                            value={whatsapp}
                            size='medium'
                            onChange={(event) => {
                                setWhatsapp(event.target.value)
                            }} />

                        <TextField
                            id="outlined-basic"
                            type='password'
                            label="Password"
                            style={{ marginTop: 10 }}
                            variant="outlined"
                            value={pass}
                            size='medium'
                            onChange={(event) => {
                                setPass(event.target.value)
                            }} />

                        <TextField
                            id="outlined-basic"
                            label="Confirm Password"
                            type='password'
                            style={{ marginTop: 10 }}
                            variant="outlined"
                            value={cpass}
                            size='medium'
                            onChange={(event) => {
                                setCPass(event.target.value)
                            }} />

                        <div style={{ display: 'flex', marginTop: 15, alignSelf: 'center', alignItems: 'center' }}>
                            <input
                                style={{ width: 20, height: 20 }}
                                type="checkbox"
                                checked={terms}
                                name="terms"
                                onChange={(e) => {
                                    setTerms(e.target.checked)
                                }} />
                            <div style={{ marginLeft: 15, fontSize: 16, }}>
                                I agree with <a href='/terms' style={{ color: '#04B486' }}>Terms & Conditions</a>.
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', minWidth: 280, flex: 1 }}>
                            <Link
                                onClick={() => {
                                    console.log('click');
                                    if (name === '') {
                                        showToast('error', 'Please fill details!')
                                        return
                                    }
                                    else if (email === '') {
                                        showToast('error', 'Please fill details!')
                                        return
                                    }
                                    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                                        showToast('error', 'Please enter a valid email!')
                                        return
                                    }
                                    createAccount()
                                }}
                                className='btn my-button' style={{ fontFamily: 'sans-serif', height: 55, backgroundColor: '#04B486', marginTop: 25, color: 'white', width: '100%', padding: 12, fontSize: 20, fontStyle: 'bold', border: 'none' }}>
                                Signup Now
                            </Link>
                        </div>

                        <div style={{ marginTop: 15, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ fontSize: 16 }}>Already a registered user?</div>
                            {/* <button
                                onClick={() => {
                                    window.location.href = '/affiliate-login'
                                }}
                                style={{ fontSize: 16, marginLeft: 0, border: '0px', backgroundColor: 'transparent', color: '#04B486' }}>
                                Please login here
                            </button> */}
                        </div>

                        <Link
                            onClick={() => {
                                window.location.href = '/affiliate-login'
                            }}
                            className='btn my-button' style={{ fontFamily: 'sans-serif', height: 55, backgroundColor: '#fff', border:'1px solid #04B486', marginTop: 10, color: '#04B486', width: '100%', padding: 12, fontSize: 20, fontStyle: 'bold' }}>
                            Login
                        </Link>

                    </div>

                </div>
            </div>
            <FooterTwo noHeight socialData={socialData} />
            <MyLoader loader={loader} marginTop={-10} />
        </div>
    )
}

export default Signup;