import React, { useState, useEffect } from 'react';
import '../App.css';
import db from '../firebase/firebase';
import moment from 'moment';
import { showFieldTypes } from 'react-admin';

const ShopifyOrders = () => {
    const [orders, setOrders] = useState([])
    const [affiliates, setAffiliates] = useState([])
    const [withdrawals, setWithdrawals] = useState([])
    const [tab, setTab] = useState(1)

    // load data
    useEffect(() => {
        loadOrders()
        loadAffiliates()
        loadWithdrawals()
    }, [])

    const loadOrders = () => {
        db.collection('shopifyOrders')
            .orderBy('added_date', 'desc')
            .get()
            .then(querySnapshot => {
                let temp = []
                for (let i = 0; i < querySnapshot.docs.length; i++) {
                    const doc = querySnapshot.docs[i];
                    let model = doc.data()
                    model.id = doc.id
                    temp.push(model)
                }
                // const orders = querySnapshot.docs.map(doc => doc.data());
                setOrders(temp)
            })
    }

    const loadAffiliates = () => {
        db.collection('affiliate-users')
            // .orderBy('added_date', 'desc')
            .get()
            .then(querySnapshot => {
                const items = querySnapshot.docs.map(doc => doc.data());
                setAffiliates(items)
            })
    }

    const loadWithdrawals = () => {
        // setWithdrawals([
        //     { amount: 140, code: 8300, status: 'pending' },
        //     { amount: 70, code: 5337, status: 'pending' }
        // ])
        db.collection('affiliate-withdrawals')
            .orderBy('timestamp', 'desc')
            .get()
            .then(querySnapshot => {
                let temp = []
                for (let i = 0; i < querySnapshot.docs.length; i++) {
                    const doc = querySnapshot.docs[i];
                    let model = doc.data()
                    model.id = doc.id
                    temp.push(model)
                }
                // const orders = querySnapshot.docs.map(doc => doc.data());
                setWithdrawals(temp)
            })
    }

    const showData = () => {
        if (tab == 1) {
            return showOrders()
        }
        else if (tab == 2) {
            return showAffiliates()
        }
        else if (tab == 3) {
            return showWithdrawals()
        }
    };

    const showOrders = () => {
        return orders.map((model, index) => {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingLeft: 15, paddingTop: 15, paddingBottom: 15, paddingRight: 15, marginTop: 10, border: '1px solid gray' }}>
                    <div>
                        Date: {moment(parseInt(model.added_date)).format('h:m a DD-MM-YYYY')}
                    </div>
                    {/* <div style={{ color: 'blue' }}>
                    <a href={model.logo} target='_blank'>Logo Download</a>
                </div> */}
                    <div>
                        Full App: <span style={{ color: 'blue' }}>{model.fullApp+''}</span>
                    </div>
                    <div>
                        Store Url: <span style={{ color: 'blue' }}>{model.url}</span>
                    </div>
                    <div>
                        Platform: <span style={{ color: 'blue' }}>{model.platform}</span>
                    </div>
                    <div>
                        Email: <span style={{ color: 'blue' }}>{model.email}</span>
                    </div>
                    <div>
                        Whatsapp: <span style={{ color: 'blue' }}>{model.whatsapp}</span>
                    </div>
                    <div>
                        Code: <span style={{ color: 'blue' }}>{model.code}</span>
                    </div>
                    <div>
                        Status: <span style={{ color: 'blue' }}>{model.status}</span>
                    </div>
                    <select
                        style={{ width: 150 }}
                        value={model.status}
                        name="status" id="status"
                        onChange={(event) => {
                            let value = event.target.value
                            updateOrderStatus(model, value)
                        }}>
                        <option value="pending">Pending</option>
                        <option value="complete">Complete</option>
                    </select>
                </div>
            );
        })
    }

    const updateOrderStatus = (order, status) => {
        // user.balance = user.balance + order.commission (when order gets complete)
        const code = order.code
        if (code !== '') {
            db.collection('affiliate-users')
                .where('code', '==', code + '')
                .get()
                .then((res) => {
                    if (res.docs.length > 0) {
                        let userID = res.docs[0].id
                        let user = res.docs[0].data()
                        let balance = user.balance + order.commission
                        db.collection('affiliate-users')
                            .doc(userID)
                            .set({ balance: balance }, { merge: true })
                    }
                })
        }
        db.collection('shopifyOrders')
            .doc(order.id)
            .set({
                status: status
            }, { merge: true })
    }

    const showAffiliates = () => {
        // alert(affiliates.length)
        return affiliates.map((model, index) => {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingLeft: 15, paddingTop: 15, paddingBottom: 15, paddingRight: 15, marginTop: 10, border: '1px solid gray' }}>
                    {model.added_date &&
                        <div>
                            Date: {moment(model.added_date.toDate()).format('h:m a DD-MM-YYYY')}
                        </div>
                    }
                    <div>
                        Name: <span style={{ color: 'blue' }}>{model.name}</span>
                    </div>
                    <div>
                        Code: <span style={{ color: 'blue' }}>{model.code}</span>
                    </div>
                    <div>
                        Email: <span style={{ color: 'blue' }}>{model.email}</span>
                    </div>
                    <div>
                        Whatsapp: <span style={{ color: 'blue' }}>{model.whatsapp}</span>
                    </div>
                    <div>
                        Balance: <span style={{ color: 'blue' }}>${model.balance}</span>
                    </div>
                    {model.payment &&
                        <div>
                            Payment: <span style={{ color: 'blue' }}>{model.payment.accountType}, {model.payment.accountNo}, {model.payment.name}, {model.payment.bankName}</span>
                        </div>
                    }
                </div>
            );
        })
    };

    const showWithdrawals = () => {
        return withdrawals.map((model, index) => {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingLeft: 15, paddingTop: 15, paddingBottom: 15, paddingRight: 15, marginTop: 10, border: '1px solid gray' }}>
                    {model.timestamp &&
                        <div>
                            Date: {moment(model.timestamp.toDate()).format('h:m a DD-MM-YYYY')}
                        </div>
                    }
                    <div>
                        Amount: <span style={{ color: 'blue' }}>${model.amount}</span>
                    </div>
                    <div>
                        Code: <span style={{ color: 'blue' }}>{model.code}</span>
                    </div>
                    <div>
                        Status: <span style={{ color: 'blue' }}>{model.status}</span>
                    </div>
                    {model.payment &&
                        <div>
                            Payment: <span style={{ color: 'blue' }}>{model.payment.accountType}, {model.payment.accountNo}, {model.payment.name}, {model.payment.bankName}</span>
                        </div>
                    }
                    {model.status === 'pending' &&
                        <select
                            style={{ width: 150 }}
                            value={model.status}
                            name="withdraw"
                            id="withdraw"
                            onChange={(event) => {
                                let value = event.target.value
                                if (value === 'paid')
                                    updateWithdrawStatus(model, 'paid')
                            }}>
                            <option value="pending">Pending</option>
                            <option value="paid">Paid</option>
                        </select>
                    }
                </div>
            );
        })
    };

    const updateWithdrawStatus = (item, status) => {
        // user.balance = user.balance - paid amount
        const code = item.code
        if (code !== '') {
            db.collection('affiliate-users')
                .where('code', '==', code + '')
                .get()
                .then((res) => {
                    if (res.docs.length > 0) {
                        let userID = res.docs[0].id
                        let user = res.docs[0].data()
                        let balance = user.balance - item.amount
                        db.collection('affiliate-users')
                            .doc(userID)
                            .set({ balance: balance }, { merge: true })
                    }
                })
        }
        db.collection('affiliate-withdrawals')
            .doc(item.id)
            .set({
                status: status
            }, { merge: true })
    }

    return (
        <div className='main'>
            <div className='titlebar'>
                <h4>
                    Shopify Data
                </h4>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'center' }}>
                <button
                    onClick={() => {
                        setTab(1)
                    }}
                    style={{ fontSize: 14, backgroundColor: tab == 1 ? '#000' : 'white', paddingLeft: 20, paddingRight: 20, color: tab == 1 ? 'white' : '#000', border: '1px solid #000', borderTopLeftRadius: 30, borderBottomLeftRadius: 30, height: 40 }}>
                    Orders
                </button>
                <button
                    onClick={() => {
                        setTab(2)
                    }}
                    style={{ fontSize: 14, backgroundColor: tab == 2 ? '#000' : 'white', marginLeft: -1, paddingLeft: 20, paddingRight: 20, color: tab == 2 ? 'white' : '#000', border: '1px solid #000', borderTopRightRadius: 0, borderBottomRightRadius: 0, height: 40 }}>
                    Affiliates
                </button>
                <button
                    onClick={() => {
                        setTab(3)
                    }}
                    style={{ fontSize: 14, backgroundColor: tab == 3 ? '#000' : 'white', marginLeft: -1, paddingLeft: 20, paddingRight: 20, color: tab == 3 ? 'white' : '#000', border: '1px solid #000', borderTopRightRadius: 30, borderBottomRightRadius: 30, height: 40 }}>
                    Withdrawals
                </button>
            </div>

            {showData()}

        </div>
    );
}

export default ShopifyOrders;
