import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div style={{ marginLeft: 0, marginRight: 0 }}>


            <Accordion expanded={expanded === 'panel000'} onChange={handleChange('panel000')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel000bh-content"
                    id="panel000bh-header">
                    <Typography style={{ fontFamily:'sans-serif', fontWeight: 'bold', fontSize: 18 }}>
                        Is Store2app an App Builder?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{ color: 'black', fontSize: 18 }}>
                        Store2app is a team of Senior Mobile App Developers who manage everything for you. So you do not have to touch anything.

                        We offer a done-for-you service to turn your store into native apps that often look, feel and perform better than app builder apps.

                        There are no integration limits, you own your entire site design, and you'll never have to pay additional fees for any revenue generated through your apps.

                        Most app builders are also platform-first which means they are built with a DIY approach in mind. While that works for some people, customers often make the switch from various app builders including AppyPie, Tapcart and many others once they realize the limitations and hassles of having to maintain an app separately from their website experience.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel00'} onChange={handleChange('panel00')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel00bh-content"
                    id="panel00bh-header">
                    <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>
                        Will performance be an issue with my app?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{ color: 'black', fontSize: 18 }}>
                        No! You can confirm this in demo. Our solution goes beyond a simple webview implementation; it integrates native elements such as latest offer screen, notifications screen, bottom tabs, popups, and more. Furthermore, with the rapid advancements in web technology, webview-based applications have narrowed the disparity with fully custom apps considerably. One significant advantage is the seamless maintenance experience: any updates made on the website are instantly reflected in your app without the need for rebuilding. This unified approach eliminates the hassle of managing both the website and the app separately.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel0'} onChange={handleChange('panel0')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel0bh-content"
                    id="panel0bh-header">
                    <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>
                        Are there any monthly or annual charges for app development?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{ color: 'black', fontSize: 18 }}>
                        There are no monthly charges for app development and support. The initial cost includes 1 year free support. But after 1 year there will be a small annual fee for support & maintenance.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header">
                    <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>
                        What do I need to convert my shopify store to app?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{ color: 'black', fontSize: 18 }}>
                        You just need to provide store URL and Logo (optional).
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header">
                    <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>
                        Do I need to provide Apple and Google Play Store accounts?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        style={{ color: 'black', fontSize: 18 }}>
                        Yes. We will publish the app to your App Store and Google Play accounts.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header">
                    <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>
                        When will my Mobile App be ready?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        style={{ color: 'black', fontSize: 18 }}>
                        Your free demo will be ready in 24 hours. However, it might take 48 hours in some busy days. You will get a testable build which you can install on your phone and play with it. Ask for any customizations we will do them until it is ready to go live.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6bh-content"
                    id="panel6bh-header">
                    <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>
                        Will you Publish the app on Apple Store and Google Play Stores?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        style={{ color: 'black', fontSize: 18 }}>
                        Yes, definitely. Once you've tested your app and confirmed its functionality, we'll take care of publishing it to both app stores on your behalf. Please note that the approval process typically takes around 7 days due to the review procedures of the app stores. However, we assure you of its approval.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header">
                    <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>
                        What if I make changes in my shopify store?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        style={{ color: 'black', fontSize: 18 }}>
                        Your app will always be in sync with your shopify store so whatever changes you make will automatically reflect in mobile app. There will be no need to rebuild and update on app stores.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel7bh-content"
                    id="panel7bh-header">
                    <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>
                        Is it PWA App?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        style={{ color: 'black', fontSize: 18 }}>
                        No, it will be Webview based native mobile app. People will install it from Google/Apple Store just like any other app.
                    </Typography>
                </AccordionDetails>
            </Accordion>

        </div>
    )
}

export default FAQ