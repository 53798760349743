import React, {  } from 'react';
import Navbar from './Navbar/Navbar';
import Body from './Body/Body';
import Footer from './Footer/Footer';
import socialData from './socialData';

// 04B486
const Home = () => {
    return (
        <div className="body_wrapper">
            <Navbar mClass="menu_two" mainlogo="logo-11.png" stickylogo="logo-00.png" />
            <Body/>
            <Footer socialData={socialData} />
        </div>
    )
}

export default Home
