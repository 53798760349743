const socialData = {
  "socialLinks": [
    {
      "name": "Facebook",
      "url": "https://web.facebook.com/saif0347",
      "className": "social_facebook"
    },
    {
      "name": "twitter",
      "url": "",
      "className": "social_twitter"
    },
  ]
}

export default socialData;

