import React, { useState, useEffect } from 'react';
import Navbar3 from '../Navbar/Navbar3';
import Footer from '../Footer/Footer';
import socialData from '../socialData';
import TextField from '@mui/material/TextField';
import { Link } from 'react-scroll';
import db from '../../firebase/firebase';
import showToast from '../toast';
import MyLoader from '../loader'
import moment from 'moment/moment';

const darkTxt = '#000'
const themeTxt = '#113e49'
const lightTxt = '#6e6e6e'

const AffiliateHome = () => {
    // user.clicks = [{timestamp},{}]
    // shopifyOrders = [{timestamp, code, commission, status: pending/complete}]

    // user.balance = 140 + order.commission (when order gets complete)
    // affiliate-withdrawals = [{code, status: pending/paid, amount, timestamp, msg}]
    // when withdrawal status is "paid" balance becomes:
    // user.balance = user.balance - paid amount

    // user.payment = {accountType: bank/easypaisa/jazzcash, bankName, accountNo, name}

    const [tab, setTab] = useState(1)
    const [checked, setChecked] = useState(-1)
    const [withdraws, setWithdraws] = useState([])
    const [user, setUser] = useState({})

    const [clicks, setClicks] = useState(0)
    const [pending, setPending] = useState(0)
    const [complete, setComplete] = useState(0)
    const [earning, setEarning] = useState(0)
    const [loader, setLoader] = useState(false)

    const [account, setAccount] = useState('bank')
    const [bankName, setBankName] = useState('')
    const [accountNo, setAccountNo] = useState('')
    const [accountName, setAccountName] = useState('')

    useEffect(() => {
        let user = localStorage.getItem('user')
        if (user == null) {
            window.location.href = '/'
            return
        }
        if (user != null) {
            user = JSON.parse(user)
        }
        setUser(user)
        // load fresh user data
        loadUser(user)
        loadUserOrders(user)
        loadUserWithdrawals(user)
    }, [])

    // useEffect(() => {

    // }, [checked])

    const loadUser = async (user) => {
        let users = await db.collection('affiliate-users')
            .where('email', '==', user.email)
            .get()
        if (users.docs.length > 0) {
            let user = users.docs[0].data()
            user.id = users.docs[0].id
            localStorage.setItem('user', JSON.stringify(user))
            setUser(user)
            setClicks(user.clicks.length)
            setAccount(user.payment.accountType)
            setBankName(user.payment.bankName)
            setAccountNo(user.payment.accountNo)
            setAccountName(user.payment.name)
        }
    }

    const loadUserOrders = async (user) => {
        let orders = await db.collection('shopifyOrders')
            .where('code', '==', user.code + '')
            .get()
        let pending = 0
        let complete = 0
        let earning = 0
        if (orders.docs.length > 0) {
            for (let i = 0; i < orders.docs.length; i++) {
                const order = orders.docs[i].data();
                if (order.status == 'pending') {
                    pending++
                }
                else if (order.status == 'complete') {
                    complete++
                    earning = earning + order.commission
                }
            }
        }
        setPending(pending)
        setComplete(complete)
        setEarning(earning)
    }

    const loadUserWithdrawals = async (user) => {
        let withdrawals = await db.collection('affiliate-withdrawals')
            .where('code', '==', user.code + '')
            .get()
        let items = []
        if (withdrawals.docs.length > 0) {
            for (let i = 0; i < withdrawals.docs.length; i++) {
                const item = withdrawals.docs[i].data();
                item.id = withdrawals.docs[i].id
                items.push(item)
            }
            setWithdraws(items)
        }
    }

    const savePaymentInfo = () => {
        if (account === 'bank' && bankName === '') {
            showToast('error', 'Please fill complete details.')
            return
        }
        else if (accountNo === '' || accountName === '') {
            showToast('error', 'Please fill complete details.')
            return
        }

        let payment = {
            accountType: account,
            bankName: bankName,
            accountNo: accountNo,
            name: accountName
        }
        db.collection('affiliate-users')
            .doc(user.id)
            .set({ payment: payment }, { merge: true })
        showToast('success', 'Changes saved!')
        setTimeout(() => {
            loadUser(user)
        }, 1000);
    }

    // affiliate-withdrawals = [{code, status: pending/paid, amount, timestamp, msg}]
    const requestWithdraw = async () => {
        // check if balance
        if (user.balance == 0) {
            showToast('error', 'Sorry no balance!')
            return
        }
        if (user.payment.accountNo === '') {
            showToast('error', 'Please update your payment info.')
            return
        }

        // check if any pending withdraw
        for (let i = 0; i < withdraws.length; i++) {
            const item = withdraws[i];
            if (item.status === 'pending') {
                showToast('error', 'You already have a pending withdrawal.')
                return
            }
        }
        setLoader(true)
        // save
        await db.collection('affiliate-withdrawals')
            .add({
                code: user.code,
                status: 'pending',
                amount: user.balance,
                email: user.email,
                msg: '', // admin notes
                timestamp: new Date(),
                payment: user.payment
            })
        setLoader(false)
        showToast('success', 'Request submitted!')
        loadUserWithdrawals(user)
    }

    const showDashboard = () => {
        return (
            <div style={{ display: 'flex', width: '100%', marginTop: 30, flexDirection: 'column', alignItems: 'center' }}>

                <div style={{ minWidth: 350, paddingLeft: 10, paddingRight: 10, backgroundColor: 'white', height: 45, borderRadius: 5, display: 'flex', alignItems: 'center', border: '3px solid white' }}>
                    <div style={{ fontSize: 14, backgroundColor: '#fff', color: lightTxt }}>
                        Your Affiliate Link
                    </div>
                    <div style={{ width: 1, height: 20, backgroundColor: 'lightgray', marginLeft: 10, marginRight: 10 }} />
                    <div style={{ fontSize: 14, color: '#000', paddingRight: 20 }}>
                        https://store2app.org?id={user.code ? user.code : ''}
                    </div>
                    <button
                        onClick={async () => {
                            try {
                                await navigator.clipboard.writeText('https://store2app.org?id=' + user.code);
                                showToast('success', 'Link copied!')
                            }
                            catch (err) {
                                //
                            }
                        }}
                        style={{ fontSize: 14, marginRight: -5, borderRadius: 5, backgroundColor: '#04B486', paddingLeft: 10, paddingRight: 10, color: 'white', border: '1px solid #04B486' }}>
                        Copy
                    </button>
                </div>

                {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: 40 }}>
                    <button
                        style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0, border: '0px' }}
                        onClick={() => {
                            setChecked(1)
                        }}>
                        <input
                            size={30}
                            type='radio'
                            checked={checked == 1} />
                        <div style={{ marginLeft: 10 }}>
                            All Stats
                        </div>
                    </button>

                    <button
                        style={{ backgroundColor: 'transparent', marginLeft: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0, border: '0px' }}
                        onClick={() => {
                            setChecked(2)
                        }}>
                        <input
                            size={30}
                            type='radio'
                            checked={checked == 2} />
                        <div style={{ marginLeft: 10 }}>
                            Last 30 Day Stats
                        </div>
                    </button>
                </div> */}

                <div
                    style={{ width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 20, marginBottom: 0, alignItems: 'stretch', justifyContent: 'center' }}>
                    {showStat(1, clicks + '', "Clicks")}
                    {showStat(2, pending + '', "In Progress")}
                </div>
                <div
                    style={{ width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 0, marginBottom: 20, alignItems: 'stretch', justifyContent: 'center' }}>
                    {showStat(3, complete + '', "Completed")}
                    {showStat(4, '$' + earning, "Earnings")}
                </div>

            </div >
        )
    }

    const showStat = (step, stepTxt, stepDesc) => {
        return (
            <div className='step-parent3' style={{ backgroundColor: 'white', flex: 1, width: window.innerWidth <= 800 ? '95%' : '80%', alignSelf: 'center', marginTop: 15, border: '0px solid lightgray', borderRadius: 10, padding: 30, minWidth: 350, maxWidth: 400}}>
                <img
                    style={{ width: 60, height: 60, alignSelf: 'center', marginTop: 0, marginLeft: 0 }}
                    src={
                        step == 1 ?
                            require('../../image/click1.png')
                            :
                            step == 2 ?
                                require('../../image/progress1.png')
                                :
                                step == 3 ?
                                    require('../../image/complete1.png')
                                    :
                                    require('../../image/earning1.png')
                    } />
                <div
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginLeft: window.innerWidth <= 800 ? 0 : 20, marginTop: window.innerWidth <= 800 ? 20 : 0 }}>
                    <div className='step-txt2' style={{ marginTop: 0, textAlign: 'left', fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: 26, color: themeTxt }}>
                        {stepTxt}
                    </div>
                    <div className='step-txt2' style={{ textAlign: 'left', fontFamily: 'sans-serif', marginTop: 5, fontSize: 20, color: lightTxt, lineHeight: 1.5 }}>
                        {stepDesc}
                    </div>
                </div>
            </div>
        )
        // return (
        //     <div className='step-parent2' style={{ width: 150, height: 200, flex: 1, marginLeft: 10, marginRight: 10, marginTop: 15, backgroundColor: 'white', alignItems: 'center', justifyContent: 'center', borderRadius: 10 }}>
        //         <div className='step-txt' style={{ fontFamily: 'sans-serif', marginTop: 15, fontWeight: 'bold', fontSize: 36, color: themeTxt, marginBottom: 10 }}>
        //             {stepTxt}
        //         </div>
        //         <div className='step-txt' style={{ fontFamily: 'sans-serif', marginTop: 5, fontSize: 18, color: lightTxt, lineHeight: 1.5 }}>
        //             {stepDesc}
        //         </div>
        //     </div>
        // )
    };

    const showMyEarnings = () => {
        return (
            <div style={{ display: 'flex', minHeight: '50vh', width: '100%', marginTop: 30, flexDirection: 'column', alignItems: 'center' }}>

                <div style={{ minWidth: 300, paddingLeft: 10, paddingRight: 10, backgroundColor: 'white', height: 45, borderRadius: 5, display: 'flex', alignItems: 'center', border: '3px solid white' }}>
                    <div style={{ fontSize: 14, backgroundColor: '#fff', color: lightTxt }}>
                        Your Balance
                    </div>
                    <div style={{ width: 1, height: 20, backgroundColor: 'lightgray', marginLeft: 10, marginRight: 10 }} />
                    <div style={{ flex: 1, fontSize: 16, fontWeight: 'bold', color: '#04B486', paddingRight: 20 }}>
                        ${user.balance}
                    </div>
                    <button
                        onClick={() => {
                            requestWithdraw()
                        }}
                        style={{ fontSize: 14, marginRight: -5, borderRadius: 5, backgroundColor: '#04B486', paddingLeft: 10, paddingRight: 10, color: 'white', border: '1px solid #04B486' }}>
                        Withdraw
                    </button>
                </div>

                <div style={{ marginTop: 10, color: lightTxt, fontSize: 14 }}>
                    Payments are processed within 2-3 days. In case of any issues contact us at: <span style={{ color: '#01A9DB' }}>ceo@saiftech.org</span>
                </div>

                <div style={{ marginTop: 10 }} />

                {withdraws.map((item, index) => {
                    return (
                        <div style={{ minWidth: window.innerWidth < 700 ? 400 : 700, marginTop: 10, borderRadius: 10, display: 'flex', flexDirection: 'column', backgroundColor: '#fff', padding: 20 }}>
                            <div style={{ color: 'gray' }}>
                                Status: <span style={{ color: 'orange', fontWeight: 'bold' }}>{item.status}</span>
                            </div>
                            <div style={{ color: 'gray' }}>
                                Amount: <span style={{ fontWeight: 'bold', color: 'black' }}>${item.amount}</span>
                            </div>
                            <div style={{ color: 'gray' }}>
                                Requested: <span style={{ color: 'black', fontWeight: 'bold' }}>{moment(item.timestamp.toDate()).format('h:mm a DD MMM YYYY')}</span>
                            </div>
                        </div>
                    )
                })}

            </div>
        )
    }

    const showPaymentInfo = () => {
        return (
            <div style={{ display: 'flex', height: '50vh', width: '100%', marginTop: 30, flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ fontSize: 16, color: lightTxt }}>
                    Select where you want to recieve your payments.
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>

                    <button
                        style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0, border: '0px', backgroundColor: 'transparent' }}
                        onClick={() => {
                            setAccount('bank')
                            console.log('a: ' + user.payment.accountType);
                            if (user.payment.accountType !== 'bank') {
                                console.log('1');
                                setBankName('')
                                setAccountNo('')
                                setAccountName('')
                            }
                            else {
                                console.log('2');
                                setBankName(user.payment.bankName)
                                setAccountNo(user.payment.accountNo)
                                setAccountName(user.payment.name)
                            }
                        }}>
                        <input
                            size={30}
                            type='radio'
                            checked={account === 'bank'} />
                        <div style={{ marginLeft: 10 }}>
                            Bank Account
                        </div>
                    </button>
                    <button
                        style={{ marginLeft: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0, border: '0px', backgroundColor: 'transparent' }}
                        onClick={() => {
                            setAccount('easypaisa')
                            if (user.payment.accountType !== 'easypaisa') {
                                setBankName('')
                                setAccountNo('')
                                setAccountName('')
                            }
                            else {
                                setBankName(user.payment.bankName)
                                setAccountNo(user.payment.accountNo)
                                setAccountName(user.payment.name)
                            }
                        }}>
                        <input
                            size={30}
                            type='radio'
                            checked={account === 'easypaisa'} />
                        <div style={{ marginLeft: 10 }}>
                            Easypaisa
                        </div>
                    </button>
                    <button
                        style={{ marginLeft: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0, border: '0px', backgroundColor: 'transparent' }}
                        onClick={() => {
                            setAccount('jazzcash')
                            if (user.payment.accountType !== 'jazzcash') {
                                setBankName('')
                                setAccountNo('')
                                setAccountName('')
                            }
                            else {
                                setBankName(user.payment.bankName)
                                setAccountNo(user.payment.accountNo)
                                setAccountName(user.payment.name)
                            }
                        }}>
                        <input
                            size={30}
                            type='radio'
                            checked={account === 'jazzcash'} />
                        <div style={{ marginLeft: 10 }}>
                            JazzCash
                        </div>
                    </button>
                </div>

                <div
                    style={{ paddingTop: 0, marginTop: 15, display: 'flex', flexDirection: 'column', overflow: 'scroll' }}>
                    {account === 'bank' &&
                        <input
                            style={{ height: 50, marginBottom: 10, border: '1px solid #fff', paddingLeft: 20, paddingRight: 20, borderRadius: 5, minWidth: 400, backgroundColor: 'white', color: 'black' }}
                            type='text'
                            placeholder='Bank Name'
                            value={bankName}
                            onChange={(event) => {
                                setBankName(event.target.value)
                            }} />
                    }
                    <input
                        style={{ height: 50, border: '1px solid #fff', paddingLeft: 20, paddingRight: 20, borderRadius: 5, minWidth: 400, backgroundColor: 'white', color: 'black' }}
                        type='text'
                        placeholder='Account No.'
                        value={accountNo}
                        onChange={(event) => {
                            setAccountNo(event.target.value)
                        }} />
                    <input
                        style={{ marginTop: 10, height: 50, border: '1px solid #fff', paddingLeft: 20, paddingRight: 20, borderRadius: 5, minWidth: 400, backgroundColor: 'white', color: 'black' }}
                        type='text'
                        placeholder='Name'
                        value={accountName}
                        onChange={(event) => {
                            setAccountName(event.target.value)
                        }} />
                    <Link
                        onClick={() => {
                            savePaymentInfo()
                        }}
                        className='btn my-button' style={{ fontFamily: 'sans-serif', borderRadius: 5, height: 50, backgroundColor: '#04B486', marginTop: 15, color: 'white', width: '100%', padding: 12, fontSize: 18, fontStyle: 'bold', border: 'none' }}>
                        Save Changes
                    </Link>
                </div>
            </div>
        )
    }

    return (
        <div className="body_wrapper">
            <Navbar3 mClass="menu_two" mainlogo="logo-11.png" stickylogo="logo-00.png" />
            <div style={{ display: 'flex', flex: 1, backgroundColor: '#f2f2f2', marginTop: 70, color: 'black', fontFamily: 'sans-serif' }}>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', padding: 30, alignItems: 'stretch' }}>

                    <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'center' }}>
                        <button
                            onClick={() => {
                                setTab(1)
                            }}
                            style={{ fontSize: 14, backgroundColor: tab == 1 ? '#000' : 'white', paddingLeft: 20, paddingRight: 20, color: tab == 1 ? 'white' : '#000', border: '1px solid #000', borderTopLeftRadius: 30, borderBottomLeftRadius: 30, height: 40 }}>
                            Affiliate Dashboard
                        </button>
                        <button
                            onClick={() => {
                                setTab(2)
                            }}
                            style={{ fontSize: 14, backgroundColor: tab == 2 ? '#000' : 'white', marginLeft: -1, paddingLeft: 20, paddingRight: 20, color: tab == 2 ? 'white' : '#000', border: '1px solid #000', borderTopRightRadius: 0, borderBottomRightRadius: 0, height: 40 }}>
                            Withdrawals
                        </button>
                        <button
                            onClick={() => {
                                setTab(3)
                            }}
                            style={{ fontSize: 14, backgroundColor: tab == 3 ? '#000' : 'white', marginLeft: -1, paddingLeft: 20, paddingRight: 20, color: tab == 3 ? 'white' : '#000', border: '1px solid #000', borderTopRightRadius: 30, borderBottomRightRadius: 30, height: 40 }}>
                            Payment Info
                        </button>
                    </div>

                    {tab == 1 ?
                        showDashboard()
                        :
                        tab == 2 ?
                            showMyEarnings()
                            :
                            showPaymentInfo()
                    }

                </div>
            </div>
            <Footer socialData={socialData} />
            <MyLoader loader={loader} marginTop={-10} />
        </div>
    )
}

export default AffiliateHome;