import React from 'react';
import TestimonialSlider from './TestimonialSlider';

const Testimonials = () => {
  return (
    <section id="testimonial" style={{ paddingTop: 20, paddingBottom: 0 }}>
      <div className="">
        <div className="testimonial_area">
          <TestimonialSlider />
        </div>
      </div>
    </section>
  )
}
export default Testimonials;