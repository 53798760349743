import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const darkTxt = '#000'
const themeTxt = '#113e49'
const lightTxt = '#6e6e6e'

const FAQAffiliate = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div style={{ marginLeft: 0, marginRight: 0, maxWidth:800, }}>

            <Accordion style={{backgroundColor:'#fafafa'}} expanded={expanded === 'panel001'} onChange={handleChange('panel001')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel001bh-content"
                    id="panel001bh-header">
                    <Typography style={{ fontFamily: 'sans-serif', fontSize: 18 }}>
                        What service Store2app provide?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{ color: lightTxt, fontSize: 18 }}>
                        We convert Shopify stores into native mobile apps for iOS and Android. Mobile apps are essential for ecommerce success, and Shopify store owners understand this, making our service an easy sell.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion style={{backgroundColor:'#fafafa'}} expanded={expanded === 'panel006'} onChange={handleChange('panel006')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel006bh-content"
                    id="panel006bh-header">
                    <Typography style={{ fontFamily: 'sans-serif', fontSize: 18 }}>
                        Who are my target audience to share link with?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{ color: lightTxt, fontSize: 18 }}>
                        Shopify store owners.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion style={{backgroundColor:'#fafafa'}} expanded={expanded === 'panel005'} onChange={handleChange('panel005')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel005bh-content"
                    id="panel005bh-header">
                    <Typography style={{ fontFamily: 'sans-serif', fontSize: 18 }}>
                        Are there any requirements to join this program?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{ color: lightTxt, fontSize: 18 }}>
                        Absolutely not. Anybody can join this program and make passive income.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion style={{backgroundColor:'#fafafa'}} expanded={expanded === 'panel000'} onChange={handleChange('panel000')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel000bh-content"
                    id="panel000bh-header">
                    <Typography style={{ fontFamily: 'sans-serif', fontSize: 18 }}>
                        How do I promote affiliate link?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{ color: lightTxt, fontSize: 18 }}>
                        If you're a Shopify developer or have Shopify store clients, recommend our service using your affiliate link. Your clients trust you, so your recommendation will make a big impact. You can also using Facebook Groups or other social platforms to reach store owners.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion style={{backgroundColor:'#fafafa'}} expanded={expanded === 'panel003'} onChange={handleChange('panel003')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel003bh-content"
                    id="panel003bh-header">
                    <Typography style={{ fontFamily: 'sans-serif', fontSize: 18 }}>
                        What is your payment policy?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{ color: lightTxt, fontSize: 18 }}>
                        It's simple: you get paid as soon as we receive payment from the client. Your commission will appear on your dashboard for each successful order once the client's payment is completed.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion style={{backgroundColor:'#fafafa'}} expanded={expanded === 'panel002'} onChange={handleChange('panel002')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel002bh-content"
                    id="panel002bh-header">
                    <Typography style={{ fontFamily: 'sans-serif', fontSize: 18 }}>
                        What is your withdrawal policy?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{ color: lightTxt, fontSize: 18 }}>
                        There are no withdrawal limits. You can request a withdrawal anytime, and it will be processed within 2-3 days.
                    </Typography>
                </AccordionDetails>
            </Accordion>

        </div>
    )
}

export default FAQAffiliate