import React, { } from 'react';
import Navbar1 from './Navbar/Navbar1';
import Body from './Body/Body';
import Footer from './Footer/Footer';
import socialData from './socialData';

const Terms = ()=> {
    return (
        <div className="body_wrapper">
            <Navbar1 mClass="menu_two" mainlogo="logo-11.png" stickylogo="logo-00.png" />
            <div style={{padding:30, marginTop:100, color:'black', fontFamily:'sans-serif'}}>
                <h2 style={{fontFamily:'sans-serif'}}>Terms of Use</h2>

                <p>Welcome to Store2App, a mobile app development service provided by Saifal N. By accessing or using our services, you agree to be bound by the following terms and conditions ("Terms of Use"). Please read these Terms of Use carefully before using our services.</p>

                <h4 style={{fontFamily:'sans-serif'}}>Acceptance of Terms</h4>
                <p>By accessing and using Store2App, you accept and agree to be bound by these Terms of Use and our Privacy Policy. If you do not agree to these terms, you may not use our services.</p>

                <h4 style={{fontFamily:'sans-serif'}}>Description of Services</h4>
                <p>Store2App converts Shopify stores into mobile applications. We provide app development, customization, and related services to enhance the mobile presence of your e-commerce business.</p>

                <h4 style={{fontFamily:'sans-serif'}}>User Obligations</h4>
                <p>You agree to:</p>
                <ul>
                    <li>Provide accurate, current, and complete information as required for the service.</li>
                    <li>Maintain the security and confidentiality of your account credentials.</li>
                    <li>Use the services only for lawful purposes and in accordance with these Terms of Use.</li>
                </ul>

                <h4 style={{fontFamily:'sans-serif'}}>Intellectual Property</h4>
                <p>All content, trademarks, and data on our website and within the developed mobile applications, including but not limited to text, graphics, logos, icons, and software, are the property of Store2App or its licensors and are protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from any of the content without our express written consent.</p>

                <h4 style={{fontFamily:'sans-serif'}}>Payment Terms</h4>
                <p>By using our services, you agree to pay the fees specified at the time of your order. All payments are due according to the payment terms specified in your service agreement. Failure to make timely payments may result in suspension or termination of services.</p>

                <h4 style={{fontFamily:'sans-serif'}}>Confidentiality</h4>
                <p>Both parties agree to keep all confidential information, including business processes, strategies, and proprietary information, disclosed during the course of this agreement confidential and not to use it for any purpose other than fulfilling the obligations of these Terms of Use.</p>

                <h4 style={{fontFamily:'sans-serif'}}>Limitation of Liability</h4>
                <p>In no event shall Store2App be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use our services, even if we have been advised of the possibility of such damages.</p>

                <h4 style={{fontFamily:'sans-serif'}}>Termination</h4>
                <p>We reserve the right to terminate or suspend your access to our services, without prior notice or liability, for any reason, including but not limited to breach of these Terms of Use.</p>

                <h4 style={{fontFamily:'sans-serif'}}>Modifications to Terms</h4>
                <p>Store2App reserves the right to modify these Terms of Use at any time. We will notify you of any changes by posting the new Terms of Use on our website. Your continued use of the services after such changes constitutes your acceptance of the new Terms of Use.</p>

                <h4 style={{fontFamily:'sans-serif'}}>Governing Law</h4>
                <p>These Terms of Use shall be governed and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.</p>

                <h4 style={{fontFamily:'sans-serif'}}>Contact Information</h4>
                <p>If you have any questions about these Terms of Use, please contact us at:</p>
                <p style={{color:'#047857'}}>Email: ceo@saiftech.org
                <br />Phone: +923074052841
                <br />Address: Sialkot, Pakistan</p>
            </div>
            <Footer socialData={socialData} />
        </div>
    )
}

export default Terms;