import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyARLHBSGw-PqDinM5IAcwy6QWhiIKbztEA",
  authDomain: "",
  databaseURL: "",
  projectId: "shopify-webview",
  storageBucket: "gs://shopify-webview.appspot.com",
  messagingSenderId: "75509940280"
});
const db = firebaseApp.firestore();
export const storage = firebase.storage()
export default db;