import React, { Component } from 'react';
import Slider from 'react-slick';
import Fade from 'react-reveal/Fade';
import { getMobileView } from '../../mobile_view';

class PortfolioSlider extends Component {

    state = {
        portType: 'Android',
        photos: [
            require('../../image/web-to-app-samples/r4-portrait.jpg'),
            require('../../image/web-to-app-samples/r3-portrait.jpg'),
            // require('../../image/web-to-app-samples/s33-portrait.jpg'),
            require('../../image/web-to-app-samples/r2-portrait.jpg'),
            require('../../image/web-to-app-samples/r1-portrait.jpg'),
            // require('../../image/web-to-app-samples/s55-portrait.jpg'),
        ],
        loader: true,
    }

    componentDidMount() {
        //
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: true,
            autoplay: true,
            pauseOnHover: true,
            autoplaySpeed: 3000,
            slidesToShow: this.state.portType === 'Website' ? 1 : 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: this.state.portType === 'Website' ? 1 : 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: this.state.portType === 'Website' ? 1 : 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <section className="portfolio_slider_area" style={{ paddingTop: 0, paddingBottom: 50 }} id="portfolio">
                <div className="container">
                    <Slider {...settings} className="portfolio_slider">
                        {
                            this.state.photos.map((photo, index) => {
                                return (
                                    <Fade bottom cascade duration={500}>
                                        <div className="p_item">
                                            <div className="portfolio_content">
                                                {getMobileView(photo, '')}
                                            </div>
                                        </div>
                                    </Fade>
                                )
                            })
                        }
                    </Slider>

                </div>
            </section>
        )
    }
}

export default PortfolioSlider;