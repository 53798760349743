import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Link } from 'react-scroll';
import showToast from '../toast';
import FooterTwo from '../Footer/Footer';
import Navbar1 from './../Navbar/Navbar1';
import socialData from '../socialData';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../redux/Actions'
import db from '../../firebase/firebase';
import MyLoader from '../loader'

const darkTxt = '#000'
const themeTxt = '#113e49'
const lightTxt = '#6e6e6e'

const Login = (props) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useDispatch()

    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const loginAccount = async () => {
        setLoader(true)
        let users = await db.collection('affiliate-users')
            .where('email', '==', email.toLowerCase())
            .where('password', '==', pass.toLowerCase())
            .get()
        if (users.docs.length > 0) {
            // show success screen
            let user = users.docs[0].data()
            localStorage.setItem('user', JSON.stringify(user))
            navigate('/affiliate-home', { replace: false });
        }
        else {
            showToast('error', 'Sorry wrong login info.')
        }
        setLoader(false)
    }

    const changePasswordEmail = async () => {
        if (email == '') {
            showToast('error', 'Please enter email to change password.')
            return
        }
        let users = await db.collection('affiliate-users')
            .where('email', '==', email.toLowerCase())
            .get()
        if (users.docs.length > 0) {
            // show success screen
            let user = users.docs[0].data()
            let mailParams = {
                "accessToken": '03X4H03hf8mNX3vmdhiLI',
                "service_id": 'service_vkaxqvg',
                "template_id": 'template_lv3syqu',
                "user_id": 'Hrb9PrGzCxo7wOfqg',
                "template_params": {
                    "email": email,
                    "message": 'Your login password for Store2App affiliate account is: ' + user.password,
                }
            }
            fetch('https://api.emailjs.com/api/v1.0/email/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(mailParams)
            })
                .then(response => response.text())
                .then(result => {
                    console.log('email sent: ' + result.text);
                    showToast('success', 'Please check your email to find password.')
                })
                .catch(err => {
                    console.log(err)
                    showToast('error', 'Some network problem. Please try later.')
                });
        }
        else {
            showToast('error', 'Email not found.')
        }
    }

    return (

        <div className="body_wrapper" style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f4f4f9' }}>

            <Navbar1 mClass="menu_two" mainlogo="logo-11.png" stickylogo="logo-00.png" />

            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', marginTop: 120, marginBottom: 50, paddingBottom: 50, width: window.innerWidth <= 800 ? '90%' : '50%', alignSelf: 'center', paddingTop: 30, borderRadius: 10, alignItems: 'stretch' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ fontSize: 26, fontFamily: 'sans-serif', marginTop: 10, color: '#113E49' }}>
                        Login Affiliate Account
                    </div>
                </div>

                <div className='shopify-step-parent' style={{ width: '80%', display: 'flex', alignSelf: 'center', flexDirection: 'column', alignItems: 'stretch' }}>

                    <div
                        className='col'
                        style={{ flex: 1, paddingTop: 0, marginTop: 20, display: 'flex', flexDirection: 'column', overflow: 'scroll' }}>

                        <TextField
                            id="outlined-basic"
                            label="Email"
                            style={{ marginTop: 10 }}
                            variant="outlined"
                            value={email}
                            size='medium'
                            onChange={(event) => {
                                setEmail(event.target.value)
                            }} />

                        <TextField
                            id="outlined-basic"
                            type='password'
                            label="Password"
                            style={{ marginTop: 10 }}
                            variant="outlined"
                            value={pass}
                            size='medium'
                            onChange={(event) => {
                                setPass(event.target.value)
                            }} />

                        <div style={{ display: 'flex', flexDirection: 'column', minWidth: 280, flex: 1 }}>
                            <Link
                                onClick={() => {
                                    console.log('click');
                                    if (email === '') {
                                        showToast('error', 'Please fill details!')
                                        return
                                    }
                                    else if (pass === '') {
                                        showToast('error', 'Please fill details!')
                                        return
                                    }
                                    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                                        showToast('error', 'Please enter a valid email!')
                                        return
                                    }
                                    loginAccount()
                                }}
                                className='btn my-button' style={{ fontFamily: 'sans-serif', height: 55, backgroundColor: '#04B486', marginTop: 25, color: 'white', width: '100%', padding: 12, fontSize: 20, fontStyle: 'bold', border: 'none' }}>
                                Login
                            </Link>
                        </div>

                        <div style={{ marginTop: 15, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ fontSize: 16 }}>Forgot Password?</div>
                            <button
                                onClick={() => {
                                    changePasswordEmail()
                                }}
                                style={{ fontSize: 16, marginLeft: 0, border: '0px', backgroundColor: 'transparent', color: '#04B486' }}>
                                Click here to find
                            </button>
                        </div>

                    </div>

                </div>
            </div>
            <FooterTwo noHeight socialData={socialData} />
            <MyLoader loader={loader} marginTop={-10} />
        </div>
    )
}

export default Login;