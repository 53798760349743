import React, { useState, useEffect } from 'react';
import FooterTwo from '../Footer/Footer';
import Navbar2 from './../Navbar/Navbar2';
import socialData from '../socialData';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Reveal } from 'react-reveal/';
import FAQAffiliate from '../FAQ/FAQAffiliate';

const darkTxt = '#000'
const themeTxt = '#113e49'
const lightTxt = '#6e6e6e'

const Affiliate = (props) => {
    const navigate = useNavigate();

    const dispatch = useDispatch()
    let shopifyData = useSelector(state => state.MyReducer.shopifyData);

    const [email, setEmail] = useState('');
    const [platform, setPlatfrom] = useState(1);

    // useEffect(()=>{
    //     let user = localStorage.getItem('user')
    //     if(user != null){
    //        navigate('/affiliate-home')
    //     }
    // }, [])

    useEffect(() => {
        if (shopifyData != null) {
            if (shopifyData.step1) {
                setEmail(shopifyData.step1.email)
                setPlatfrom(shopifyData.step1.platform)
            }
        }
        if (localStorage.getItem('user') != null) {
            window.location.href = '/affiliate-home'
        }
    }, [])


    const showBenefit = (step, stepTxt, stepDesc) => {
        return (
            <div className='step-parent1' style={{ backgroundColor: 'white', flex: 1, width: window.innerWidth <= 800 ? '95%' : '80%', alignSelf: 'center', marginTop: 15, border: '0px solid lightgray', borderRadius: 10, padding: 30, minWidth: 350 }}>
                <img
                    style={{ width: step >= 3 ? 80 : 90, height: step >= 3 ? 80 : 90, alignSelf: 'center', marginTop: step >= 3 ? 6 : 0, marginLeft: step >= 3 ? 5 : 0 }}
                    src={step == 1 ?
                        require('../../image/investment.png')
                        :
                        step == 2 ?
                            require('../../image/trust.png')
                            :
                            step == 3 ?
                                require('../../image/data-analytics.png')
                                :
                                require('../../image/loan.png')} />
                <div
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: window.innerWidth <= 800 ? 0 : step >= 3 ? 25 : 20, marginTop: window.innerWidth <= 800 ? 20 : 0 }}>
                    <div className='step-txt1' style={{ marginTop: 0, textAlign: 'left', alignSelf: 'flex-start', fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: 20, color: themeTxt }}>
                        {stepTxt}
                    </div>
                    <div className='step-txt1' style={{ textAlign: 'left', alignSelf: 'flex-start', fontFamily: 'sans-serif', marginTop: 5, fontSize: 18, color: lightTxt, lineHeight: 1.5 }}>
                        {stepDesc}
                    </div>
                </div>
            </div>
        )
    };

    const showBenefit1 = (step, stepTxt, stepDesc) => {
        return (
            <div className='step-parent' style={{ flex: 1, marginLeft: 10, marginRight: 10, marginTop: 30 }}>
                <img
                    style={{ width: 80, height: 80, alignSelf: 'center' }}
                    src={step == 1 ?
                        require('../../image/code.png')
                        :
                        step == 2 ?
                            require('../../image/loud.png')
                            :
                            require('../../image/design.png')} />
                <div className='step-txt' style={{ fontFamily: 'sans-serif', marginTop: 15, fontWeight: 'bold', fontSize: 20, color: themeTxt }}>
                    {stepTxt}
                </div>
                <div className='step-txt' style={{ fontFamily: 'sans-serif', marginTop: 5, fontSize: 18, color: lightTxt, lineHeight: 1.5 }}>
                    {stepDesc}
                </div>
            </div>
        )
    };

    const joinNow = () => {
        window.location.href = '/affiliate-signup'
    }

    return (

        <div className="body_wrapper" style={{ display: 'flex', flexDirection: 'column' }}>
            <Navbar2 mClass="menu_two" mainlogo="logo-11.png" stickylogo="logo-00.png" />

            <div style={{ marginBottom: 0, display: 'flex', alignSelf: 'center', flexDirection: 'column', alignItems: 'center' }}>

                <div className='free-demo-bg padding-affiliate' style={{ width: '100%', backgroundColor: '#000', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                    <div className="intro-white">
                        <Reveal effect="fadeInUp">
                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='home-item-txt-left'>
                                    <div style={{ marginBottom: 10, fontSize: 16, color: 'white', fontFamily: 'sans-serif' }}>
                                        Our Partner Program
                                    </div>
                                    <div className='web-to-app-title1' style={{ lineHeight: 1.1, fontWeight: 'bold', color: 'white', fontFamily: 'sans-serif' }}>
                                        Earn up to $70 by just sharing a link
                                    </div>
                                    <div style={{ fontFamily: 'sans-serif', marginTop: 5, fontSize: 18, color: 'white' }}>
                                        Have shopfiy store owners in your contact? Earn up to $70 on each order by recommending our awesome mobile app service to them.
                                    </div>
                                    <div style={{ display: 'flex', marginTop: 20, marginBottom: 40 }}>
                                        <a
                                            onClick={joinNow}
                                            className='btn my-button' style={{ minWidth: 250, fontFamily: 'sans-serif', backgroundColor: '#04B486', color: 'white', marginTop: 10, padding: 12, fontSize: 20, fontStyle: 'bold', border: 'none' }}>
                                            Start Earning Today
                                        </a>
                                    </div>
                                </div>
                                <div className='home-item-img' style={{ backgroundColor: 'transparent', display: 'flex', justifyContent: 'center' }}>
                                    <img
                                        style={{ width: 400, height: 400, border: '0.5px solid gray', alignSelf: 'flex-end', borderRadius: 300 }}
                                        src={require('../../image/money3.png')} />
                                </div>
                            </div>
                        </Reveal>
                    </div>
                </div>


                <div id='demo' className='padding-affiliate' style={{ width: '100%', backgroundColor: '#f4f4f9', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 10 }}>
                    <div className="intro-white">
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20 }}>
                            <Reveal effect="fadeInUp">
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className='web-to-app-title1' style={{ marginBottom: 10, fontFamily: 'sans-serif', textAlign: 'center', color: themeTxt }}>
                                        Why join us?
                                    </div>
                                    <div
                                        style={{ width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'column', marginTop: 20, marginBottom: 20, alignItems: 'stretch', justifyContent: 'center' }}>
                                        {showBenefit(4, 'Highly Afforable', "Our service is super affordable and easy to sell, so you can make a handsome profit while doing nothing.")}
                                        {showBenefit(1, 'High Earnings Potential', "Earn fixed $70 on each order, providing a significant income boost for every successful referral.")}
                                        {showBenefit(2, 'Trusted Service', "Our mobile apps boost user experience and sales for shopify stores, attracting store owners.")}
                                        {showBenefit(3, 'Real-Time Tracking', "Use our simple affiliate dashboard to monitor your referrals and track your earnings in real-time.")}
                                    </div>
                                </div>
                                <div style={{ alignSelf: 'center', display: 'flex', marginTop: 15, marginBottom: 0 }}>
                                    <a
                                        onClick={joinNow}
                                        className='btn my-button' style={{ minWidth: 250, fontFamily: 'sans-serif', backgroundColor: '#04B486', color: 'white', marginTop: 10, padding: 12, fontSize: 20, fontStyle: 'bold', border: 'none' }}>
                                        Join Now
                                    </a>
                                </div>
                            </Reveal>
                        </div>
                    </div>
                </div>

                <div id='demo' className='padding-affiliate' style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 10 }}>
                    <div className="intro-white">
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20 }}>
                            <Reveal effect="fadeInUp">
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className='web-to-app-title1' style={{ marginBottom: 10, fontFamily: 'sans-serif', textAlign: 'center', color: themeTxt }}>
                                        Who benefits the most?
                                    </div>
                                    <div style={{ fontFamily: 'sans-serif', marginTop: 5, fontSize: 18, color: lightTxt, lineHeight: 1.5 }}>
                                        Anyone who works with or has worked with Shopify stores can benefit the most.
                                    </div>
                                    <div
                                        style={{ width: '100%', flexDirection: 'row', flexWrap: 'wrap', alignSelf: 'center', display: 'flex', marginTop: 20, marginBottom: 20, alignItems: 'stretch', justifyContent: 'center' }}>
                                        {showBenefit1(1, 'Shopify Developers', "Developers who build and customize Shopify stores.")}
                                        {showBenefit1(2, 'Digital Marketers', "Individuals who provide digital marketing services to stores.")}
                                        {showBenefit1(3, 'Web Designers', "Designers who design and optimize shopfiy stores.")}
                                    </div>
                                </div>
                            </Reveal>
                        </div>
                    </div>
                </div>


                <div id='demo' className='padding-affiliate' style={{ width: '100%', backgroundColor: '#f4f4f9', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 10 }}>
                    <div className="intro-white">
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20 }}>
                            <Reveal effect="fadeInUp">
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className='web-to-app-title1' style={{ marginBottom: 10, fontFamily: 'sans-serif', textAlign: 'center', color: themeTxt }}>
                                        How it works?
                                    </div>
                                    <div style={{ maxWidth: 800, display: 'flex', flexDirection: 'column', marginTop: 50 }}>

                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                            {window.innerWidth >= 800 &&
                                                <div style={{ width: 5, marginRight: -67, height: 300, backgroundColor: '#f2f2f2' }} />
                                            }

                                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 20, paddingRight: 20 }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div style={{ display: 'flex', fontSize: 36, fontWeight: 'bold', color: 'white', alignItems: 'center', justifyContent: 'center', width: 90, height: 90, backgroundColor: themeTxt, borderRadius: 50 }}>
                                                        1
                                                    </div>
                                                    <div className='margin-left-30' style={{ minWidth: 350, display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                        <div style={{ fontSize: 26, fontFamily: 'sans-serif', color: themeTxt }}>
                                                            Join the Program
                                                        </div>
                                                        <div style={{ fontFamily: 'sans-serif', marginTop: 5 }}>
                                                            Joining our affiliate program is quick and easy. Register to instantly access your unique affiliate link.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ display: 'flex', marginTop: 60, flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div style={{ display: 'flex', fontSize: 36, fontWeight: 'bold', color: 'white', alignItems: 'center', justifyContent: 'center', width: 90, height: 90, backgroundColor: themeTxt, borderRadius: 50 }}>
                                                        2
                                                    </div>
                                                    <div className='margin-left-30' style={{ minWidth: 350, display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                        <div style={{ fontSize: 26, fontFamily: 'sans-serif', color: themeTxt }}>
                                                            Share Your Link
                                                        </div>
                                                        <div style={{ fontFamily: 'sans-serif', marginTop: 5, }}>
                                                            Share your affiliate link with clients, your network, website, or social media—the more you share, the more you earn!
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ display: 'flex', marginTop: 60, flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div className='step-margin-bottom' style={{ display: 'flex', fontSize: 36, fontWeight: 'bold', color: 'white', alignItems: 'center', justifyContent: 'center', width: 90, height: 90, backgroundColor: themeTxt, borderRadius: 50 }}>
                                                        3
                                                    </div>
                                                    <div className='margin-left-30' style={{ minWidth: 350, display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                        <div style={{ fontSize: 26, fontFamily: 'sans-serif', color: themeTxt }}>
                                                            Earn Commissions
                                                        </div>
                                                        <div style={{ fontFamily: 'sans-serif', marginTop: 5, }}>
                                                            Earn up to $70 per order through your affiliate link and get timely payments without any withdrawal limit.
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                        <div style={{ alignSelf: 'center', display: 'flex', marginTop: 40, marginBottom: 30 }}>
                                            <a
                                                onClick={joinNow}
                                                className='btn my-button' style={{ minWidth: 250, fontFamily: 'sans-serif', backgroundColor: '#04B486', color: 'white', marginTop: 10, padding: 12, fontSize: 20, fontStyle: 'bold', border: 'none' }}>
                                                Join Now
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </Reveal>
                        </div>
                    </div>
                </div>

                <div id='FAQ' className='padding-affiliate' style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: -30, paddingTop: 0 }}>
                    <div className="intro-white">
                        <Reveal effect="fadeInUp">
                            <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', textAlign: 'center', color: themeTxt }}>
                                Freqently Asked Questions
                            </h3>
                        </Reveal>
                        <div style={{ display: 'flex', marginTop: 30, alignItems: 'center', justifyContent: 'center', marginBottom: 40 }}>
                            <FAQAffiliate />
                        </div>
                    </div>
                </div>

                <div id='FAQ' style={{ width: '100%', backgroundColor: '#04B486', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: -30, paddingTop: 0, paddingBottom: 40 }}>
                    <div className="intro-white">
                        <Reveal effect="fadeInUp">
                            <h3 className="web-to-app-title1" style={{ fontFamily: 'sans-serif', textAlign: 'center', color: 'white' }}>
                                Ready to <span style={{ fontStyle: 'italic', fontFamily: 'sans' }}>partner up?</span>
                            </h3>
                        </Reveal>
                        <div style={{ fontFamily: 'sans-serif', fontSize: 22, marginTop: 15, textAlign: 'center', color: 'white' }}>
                            Commission is waiting,<br />
                            Register, promote, and make money.
                        </div>
                        <div style={{ alignSelf: 'center', display: 'flex', marginTop: 0, marginBottom: 0, alignItems: 'center', justifyContent: 'center' }}>
                            <a
                                onClick={joinNow}
                                className='btn my-button' style={{ minWidth: 250, fontFamily: 'sans-serif', backgroundColor: '#fff', color: '#04B486', fontWeight: 'normal', marginTop: 30, padding: 12, fontSize: 20, fontStyle: 'bold', border: 'none' }}>
                                Let's Get Started!
                            </a>
                        </div>
                    </div>
                </div>

            </div>
            <FooterTwo noHeight socialData={socialData} />
        </div>
    )
}

export default Affiliate;