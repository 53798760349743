import React, { useState } from 'react';
import Sticky from 'react-stickynode';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

const Navbar3 = (props) => {
    const navigate = useNavigate();

    var { mClass, mContainer, mainlogo, stickylogo, noButtons } = props;
    const [toggle, setToggle] = useState(false)

    return (
        <div>
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <nav className={`navbar navbar-expand-lg navbar-light ${mClass} navbar-color nav-height`} style={{ borderBottom: '0.5px solid #04B486' }}>
                    <div className={`container my-nav nav-height ${mContainer}`}>

                        <a className="navbar-brand logo_h logo-margin-left" href="/" style={{ marginTop: 10 }}>
                            <img style={{ maxWidth: 180, maxHeight: 70 }} src={require("../../image/" + mainlogo)} alt="" />
                            <img style={{ maxWidth: 180, maxHeight: 70 }} src={require("../../image/" + stickylogo)} alt="" />
                        </a>

                        {/* <div
                            onClick={() => {
                                setToggle(false)
                            }}
                            className="navbar-toggler" style={{ outline: 'none', height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            <img
                                className='toggle-icon'
                                style={{ width: toggle ? 40 : 40, height: toggle ? 40 : 40, outline: 'none', outlineColor: 'transparent', outlineWidth: 0 }}
                                src={toggle ? require("../../image/cross_icon.png") : require("../../image/menu_icon.png")} />
                        </div> */}

                        <div className="" id="navbarSupportedContent">
                            <ul className="nav navbar-nav" style={{ backgroundColor: 'transparent', alignItems: 'center', marginTop: 8 }}>

                                <a
                                    className="nav-item"
                                    style={{ backgroundColor: 'transparent' }}
                                    onClick={() => {
                                        localStorage.removeItem('user')
                                        window.location.href = '/'
                                    }}>
                                    <div className="nav-link" >
                                        Logout
                                    </div>
                                </a>

                            </ul>
                        </div>

                    </div>
                </nav>

            </Sticky>
        </div>
    )
}

export default Navbar3;