import React, { useState, useEffect } from 'react';
import FooterTwo from '../Footer/Footer';
import Navbar1 from './../Navbar/Navbar1';
import socialData from '../socialData';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const Success = (props) => {
    const navigate = useNavigate();

    const dispatch = useDispatch()
    let shopifyData = useSelector(state => state.MyReducer.shopifyData);

    const [email, setEmail] = useState('');
    const [platform, setPlatfrom] = useState(1);

    useEffect(() => {
        if (shopifyData != null) {
            if (shopifyData.step1) {
                setEmail(shopifyData.step1.email)
                setPlatfrom(shopifyData.step1.platform)
            }
        }
    }, [])

    return (

        <div className="body_wrapper" style={{ display: 'flex', flexDirection: 'column' }}>
            <Navbar1 mClass="menu_two" mainlogo="logo-11.png" stickylogo="logo-00.png" />

            <div className='shopify-step-parent' style={{ marginTop: 110, marginBottom: 50, display: 'flex', alignSelf: 'center', flexDirection: 'column', alignItems: 'center' }}>

                <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', padding: 40, border: '1px solid lightgray', borderRadius: 10 }}>
                    <div style={{ fontSize: 32, color: '#04B486', textAlign: 'center' }}>
                        Congratulations!
                    </div>
                    <div style={{ marginTop: 20, textAlign: 'center' }}>
                        Your order is confirmed for <b>Mobile App</b>! Within 24 hours our developer will reach you out sharing app demo. You can check and ask for any customizations. Please stay tuned :)
                    </div>
                    <div style={{ marginTop: 20, textAlign: 'center' }}>
                        <span style={{ fontWeight: 'bold' }}>You can contact us anytime for update:</span><br />
                        <div style={{color:'gray'}}><span style={{color:'gray'}}>Email</span>: saif052m@gmail.com</div>
                        {/* <b style={{color:'#04B486'}}>Whatsapp: +923074052841</b><br /> */}
                        <button
                            onClick={() => {
                                window.open("https://api.whatsapp.com/send?text=Hi! I just requested my free demo. Please let me know once ready!&phone=+923074052841", '_blank')
                            }}
                            style={{ alignSelf: 'center', width: '100%', marginTop: 10, textAlign: 'center', fontSize: 22, border: '0px', backgroundColor: 'transparent', fontWeight: 'bold', color: '#04B486', borderRadius: 5 }}>
                            Send hi on whatsapp!
                        </button>
                    </div>
                </div>

            </div>
            <FooterTwo noHeight socialData={socialData} />
        </div>
    )
}

export default Success;