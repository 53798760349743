import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
/*-----pages-----*/
import { NotFound } from "./404";
import Home from './component/Home';
import Step1 from './component/Shopify/Step1';
import Step2 from './component/Shopify/Step2';
import Step3 from './component/Shopify/Step3';
import Plans from './component/Shopify/Plans';
import Payment from './component/Shopify/Payment';
import Success from './component/Shopify/Success';
import ShopifyOrders from './component/shopify-orders';
import Terms from './component/terms';
import Privacy from './component/privacy';
import Affiliate from './component/Shopify/Affiliate';
import Signup from './component/Shopify/Signup';
import Login from './component/Shopify/Login';
import AffiliateHome from './component/Shopify/AffiliateHome';

import { Notifications } from 'react-push-notification';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RootStore from './redux/RootStore';
import { Provider } from 'react-redux';

class App extends Component {
  render() {
    return (
      <Provider store={RootStore}>
        <React.Fragment>
          <Notifications />
          <Router>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route component={NotFound} element={<NotFound />} />
              <Route path='/shopify-step1' component={Step1} element={<Step1 />} />
              <Route path='/shopify-step2' component={Step2} element={<Step2 />} />
              <Route path='/shopify-step3' component={Step3} element={<Step3 />} />
              <Route path='/shopify-plans' component={Plans} element={<Plans />} />
              <Route path='/shopify-payment' component={Payment} element={<Payment />} />
              <Route path='/shopify-success' component={Success} element={<Success />} />
              <Route path='/shopify-orders001' component={ShopifyOrders} element={<ShopifyOrders />} />
              <Route path='/terms' component={Terms} element={<Terms />} />
              <Route path='/privacy' component={Privacy} element={<Privacy />} />
              <Route path='/affiliate' component={Affiliate} element={<Affiliate />} />
              <Route path='/affiliate-signup' component={Signup} element={<Signup />} />
              <Route path='/affiliate-login' component={Login} element={<Login />} />
              <Route path='/affiliate-home' component={AffiliateHome} element={<AffiliateHome />} />
            </Routes>
          </Router>
          <ToastContainer />
        </React.Fragment>
      </Provider>
    );
  }
}

export default App;
