import React, { useState, useEffect } from 'react';
import FooterTwo from '../Footer/Footer';
import Navbar from './../Navbar/Navbar';
import socialData from '../socialData';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../redux/Actions'

const darkTxt = '#000'
const themeTxt = '#113e49'
const lightTxt = '#6e6e6e'

const Plans = (props) => {
    const navigate = useNavigate();

    const dispatch = useDispatch()
    let shopifyData = useSelector(state => state.MyReducer.shopifyData);

    const [platform, setPlatfrom] = useState(1);
    const [showFeatures, setShowFeatures] = useState(0);
    const [weekendPlan, setWeekendPlan] = useState(true);

    useEffect(() => {
        if (shopifyData != null) {
            if (shopifyData.step1) {
                console.log('platform: ' + shopifyData.step1.platform);
                setPlatfrom(shopifyData.step1.platform)
            }
        }
    }, [])

    const orderNowClick = (platform) => {
        // document.getElementById('free-demo').scrollIntoView()
        // let shopifyDataTemp = {
        //     ...shopifyData,
        //     step1: {
        //         ...shopifyData.step1,
        //         platform: p
        //     }
        // }
        // dispatch(Actions.setShopifyData(shopifyDataTemp))
        navigate(
            '/shopify-step1',
            {
                state: {
                    platform: platform
                }
            }
        )
    }

    const showCrossTick = (tick, txt) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    style={{ width: 18, height: 18, backgroundColor: tick ? '#CEF6D8' : '#F8E0E0', borderRadius: 10, padding: 3 }}
                    src={tick ? require('../../image/itick.png') : require('../../image/icross.png')} />
                <div style={{ fontFamily: 'sans-serif', marginLeft: 8, fontSize: 14, color: lightTxt }}>
                    {txt}
                </div>
            </div>
        )
    };

    const showPlanFeatures = (releasable) => {
        return (
            <div style={{ marginTop: 10 }}>
                {showCrossTick(releasable, 'Unlimited Revisions')}
                {showCrossTick(releasable, 'Publishing to Store')}
                {showCrossTick(true, '1 Year Free Support')}
                {showCrossTick(true, 'App Icon')}
                {showCrossTick(true, 'Splash Screen')}
                {showCrossTick(true, 'Customizable Bottom Tabs')}
                {showCrossTick(true, 'Latest Offer Screen')}
                {showCrossTick(true, 'Push Notifications')}
                {showCrossTick(true, 'Share App')}
                {showCrossTick(true, 'Rate App')}
                {showCrossTick(true, 'Pull To Refresh')}
                {/* {showCrossTick(true, 'Dark Mode & Language Settings')} */}
                {/* {showCrossTick(true, 'App Permissions Handling')} */}
                {showCrossTick(true, 'Status Bar Customization')}
                {showCrossTick(true, 'Page Loader')}
                {showCrossTick(true, 'Pinch To Zoom')}
                {showCrossTick(true, 'No Internet Screen')}
                {showCrossTick(true, 'App Syncing With Store')}
                {showCrossTick(true, 'Customize Package Name')}
                {/* {showCrossTick(true, 'Customize Version Code')} */}
            </div>
        )
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>

            <div style={{ display: 'flex', width: '95%', alignSelf: 'center', flexDirection: 'column', alignItems: 'stretch' }}>

                <div
                    className='col'
                    style={{ flex: 1, marginTop: 30, display: 'flex', flexDirection: 'column' }}>
                    <div id='pricing' style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: -30, paddingTop: 0, paddingBottom: 40, paddingLeft: 20, paddingRight: 20 }}>
                        <div style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}>

                            <h3 className="web-to-app-title" style={{ marginBottom: 25, fontFamily: 'sans-serif', textAlign: 'center', color: '#113E49' }}>
                                <span style={{ color: '#113E49' }}>Stop</span> paying <span style={{ color: '#FE2E64' }}>$100/month</span> to app builders!<br /> Get <span style={{ color: '#113E49', fontWeight: 'normal' }}>lifetime app</span> for CRAZY LOW... 👇
                            </h3>

                            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 0 }}>
                                <div style={{ display: 'flex', marginTop: 10, minWidth: 250, flex: 1, flexDirection: 'column', borderRadius: 20, border: '1px solid #04B486', padding: 20 }}>

                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {!weekendPlan ?
                                                null
                                                :
                                                <div style={{ color: '#113E49', fontWeight: 'bold', fontSize: 22, color: '#FE2E64', fontFamily: 'sans-serif', }}><strike>$199</strike></div>
                                            }
                                            <div style={{ fontFamily: 'sans-serif', color: '#113E49', fontWeight: 'bold', fontSize: 28 }}>{weekendPlan ? '$49' : '$199'} <span style={{ fontSize: 14, color: 'gray' }}>one time</span></div>

                                            <div style={{ fontFamily: 'sans-serif', marginTop: 5, color: '#113E49', fontSize: 22 }}>
                                                Android
                                            </div>
                                        </div>
                                        <img
                                            style={{ width: 80, height: 80, marginLeft: 0, marginTop:-8 }}
                                            src={require('../../image/sale1.png')} />
                                    </div>

                                    <button
                                        style={{ fontFamily: 'sans-serif', alignSelf: 'center', marginTop: 15, width: 200, fontSize: 16, border: '0px', fontWeight: 'bold', backgroundColor: '#04B486', color: 'white', borderRadius: 5, borderRadius: 30, minHeight: 40 }}
                                        onClick={() => {
                                            orderNowClick(1)
                                        }}>
                                        Get Free Demo
                                    </button>
                                    {window.innerWidth <= 700 &&
                                        <button
                                            style={{ border: '0px', backgroundColor: 'transparent', padding: 0, display: 'flex', marginTop: 10, alignItems: 'center' }}
                                            onClick={() => {
                                                if (showFeatures == 2)
                                                    setShowFeatures(0)
                                                else
                                                    setShowFeatures(2)
                                            }}>
                                            <div
                                                style={{ fontFamily: 'sans-serif', marginTop: 0, color: '#0db3c7', fontWeight: 'bold' }}>
                                                {showFeatures != 2 ? 'Show Features' : 'Hide Features'}
                                            </div>
                                            <img
                                                style={{ width: 20, height: 20, marginLeft: 5 }}
                                                src={require('../../image/down-arrow1.png')} />
                                        </button>
                                    }
                                    {(window.innerWidth > 700 || showFeatures == 2) &&
                                        showPlanFeatures(true)
                                    }
                                </div>

                                <div className='ml-10' style={{ display: 'flex', marginTop: 10, minWidth: 250, flex: 1, flexDirection: 'column', borderRadius: 20, border: '1px solid #04B486', padding: 20 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {!weekendPlan ?
                                                null
                                                :
                                                <div style={{ fontFamily: 'sans-serif', color: '#113E49', fontWeight: 'bold', fontSize: 22, color: '#FE2E64' }}><strike>$249</strike></div>
                                            }
                                            <div style={{ fontFamily: 'sans-serif', color: '#113E49', fontWeight: 'bold', fontSize: 28 }}>{weekendPlan ? '$69' : '$249'} <span style={{ fontSize: 14, color: 'gray' }}>one time</span></div>
                                            <div style={{ fontFamily: 'sans-serif', marginTop: 5, color: '#113E49', fontSize: 22 }}>
                                                iOS
                                            </div>
                                        </div>
                                        <img
                                            style={{ width: 80, height: 80, marginLeft: 0, marginTop:-8 }}
                                            src={require('../../image/sale1.png')} />
                                    </div>

                                    {/* <div style={{ lineHeight: 1.5, marginTop: 10, color: 'gray', fontWeight: 'bold', fontSize: 14 }}>
                                        Complete iOS App Including All the Features Below.
                                    </div> */}
                                    <button
                                        style={{ fontFamily: 'sans-serif', alignSelf: 'center', marginTop: 15, width: 200, fontSize: 16, border: '0px', fontWeight: 'bold', backgroundColor: '#04B486', color: 'white', borderRadius: 5, borderRadius: 30, minHeight: 40 }}
                                        onClick={() => {
                                            orderNowClick(2)
                                        }}>
                                        Get Free Demo
                                    </button>
                                    {window.innerWidth <= 700 &&
                                        <button
                                            style={{ border: '0px', backgroundColor: 'transparent', padding: 0, display: 'flex', marginTop: 10, alignItems: 'center' }}
                                            onClick={() => {
                                                if (showFeatures == 4)
                                                    setShowFeatures(0)
                                                else
                                                    setShowFeatures(4)
                                            }}>
                                            <div
                                                style={{ fontFamily: 'sans-serif', marginTop: 0, color: '#0db3c7', fontWeight: 'bold' }}>
                                                {showFeatures != 4 ? 'Show Features' : 'Hide Features'}
                                            </div>
                                            <img
                                                style={{ width: 20, height: 20, marginLeft: 5 }}
                                                src={require('../../image/down-arrow1.png')} />
                                        </button>
                                    }
                                    {(window.innerWidth > 700 || showFeatures == 4) &&
                                        showPlanFeatures(true)
                                    }
                                </div>

                                <div className='ml-10' style={{ display: 'flex', marginTop: 10, minWidth: 250, flex: 1, flexDirection: 'column', borderRadius: 20, border: '1px solid #04B486', padding: 20 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {!weekendPlan ?
                                                null
                                                :
                                                <div style={{ fontFamily: 'sans-serif', color: '#113E49', fontWeight: 'bold', fontSize: 22, color: '#FE2E64' }}><strike>$299</strike></div>
                                            }
                                            <div style={{ fontFamily: 'sans-serif', color: '#113E49', fontWeight: 'bold', fontSize: 28 }}>{weekendPlan ? '$99' : '$299'} <span style={{ fontSize: 14, color: 'gray' }}>one time</span></div>
                                            <div style={{ fontFamily: 'sans-serif', marginTop: 5, color: '#113E49', fontSize: 22 }}>
                                                Android & iOS
                                            </div>
                                        </div>
                                        <img
                                            style={{ width: 60, height: 60, marginLeft: 0 }}
                                            src={require('../../image/sale2.png')} />
                                    </div>

                                    {/* <div style={{ lineHeight: 1.5, marginTop: 10, color: 'gray', fontWeight: 'bold', fontSize: 14 }}>
                                        Both Android & iOS App Including All the Features Below.
                                    </div> */}
                                    <button
                                        style={{ fontFamily: 'sans-serif', alignSelf: 'center', marginTop: 15, width: 200, fontSize: 16, border: '0px', fontWeight: 'bold', backgroundColor: '#04B486', color: 'white', borderRadius: 5, borderRadius: 30, minHeight: 40 }}
                                        onClick={() => {
                                            orderNowClick(3)
                                        }}>
                                        Get Free Demo
                                    </button>
                                    {window.innerWidth <= 700 &&
                                        <button
                                            style={{ border: '0px', backgroundColor: 'transparent', padding: 0, display: 'flex', marginTop: 10, alignItems: 'center' }}
                                            onClick={() => {
                                                if (showFeatures == 3)
                                                    setShowFeatures(0)
                                                else
                                                    setShowFeatures(3)
                                            }}>
                                            <div
                                                style={{ fontFamily: 'sans-serif', marginTop: 0, color: '#0db3c7', fontWeight: 'bold' }}>
                                                {showFeatures != 3 ? 'Show Features' : 'Hide Features'}
                                            </div>
                                            <img
                                                style={{ width: 20, height: 20, marginLeft: 5 }}
                                                src={require('../../image/down-arrow1.png')} />
                                        </button>
                                    }
                                    {(window.innerWidth > 700 || showFeatures == 3) &&
                                        showPlanFeatures(true)
                                    }
                                </div>

                            </div>

                            {/* <img
                                style={{ marginTop: 20, alignSelf: 'center', width: window.innerWidth < 800 ? '100%' : '60%' }}
                                src={require('../../image/banner1.jpg')} /> */}

                            <div style={{ marginTop: 20, marginLeft: 30, marginRight: 30, alignSelf: 'center', fontSize: 18, color: themeTxt, fontFamily: 'sans-serif', textAlign: 'center' }}>
                                You will get your <b>App Demo within 24 hours</b>. And we will provide you <b>Unlimited Revisions</b> until you are satisfied :)
                            </div>

                            <div style={{ marginTop: 30, marginLeft: 30, marginRight: 30, paddingTop: 20, paddingBottom: 20, backgroundColor: '#047857', borderRadius: 10 }}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ marginLeft: 20, marginRight:20,  textAlign: 'left', fontStyle: 'italic', fontFamily: 'sans-serif', fontSize: 18, marginTop: 0, color: '#fff' }}>
                                        "We're seeing that the customers using the app are more engaged, they're spending more time on site, they're spending more per transaction, they're spending more overall :)"
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginRight: 30, flex: 1, justifyContent: 'flex-end', marginTop: 5 }}>
                                <div style={{marginLeft: 20, fontFamily: 'sans-serif', fontSize: 16, color: 'gray', marginTop: 0, fontStyle: 'italic' }}>
                                    - Smith, BoozeBud
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <FooterTwo socialData={socialData} /> */}
        </div>
    )
}

export default Plans; 